.border-none[data-v-ca431948]:before {
  display: none;
}
.border-none[data-v-ca431948]:after {
  display: none;
}
.flex-fix[data-v-ca431948] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-ca431948]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-ca431948] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-ca431948] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-ca431948] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-ca431948] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-ca431948] {
  *zoom: 1;
}
.clear-fix[data-v-ca431948]:before,
.clear-fix[data-v-ca431948]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.empty[data-v-ca431948] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.empty .img-box[data-v-ca431948] {
  width: 3.64rem;
  height: 2.22rem;
  margin-bottom: 0.74rem;
}
.empty .img-box .empty-img[data-v-ca431948] {
  width: 100%;
  height: 100%;
}
.empty .img-box .empty-img img[data-v-ca431948] {
  width: 3.64rem;
  height: 2.22rem;
}
.empty .empty-tip[data-v-ca431948] {
  max-width: 100%;
  font-size: 0.28rem;
  color: #909090;
  line-height: 0.4rem;
  text-align: center;
  white-space: nowrap;
}
