.border-none[data-v-70925687]:before {
  display: none;
}
.border-none[data-v-70925687]:after {
  display: none;
}
.flex-fix[data-v-70925687] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-70925687]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-70925687] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-70925687] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-70925687] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-70925687] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-70925687] {
  *zoom: 1;
}
.clear-fix[data-v-70925687]:before,
.clear-fix[data-v-70925687]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.account-page[data-v-70925687] {
  /*tab*/
  /*列表*/
  /* 列表为空*/
}
.account-page header[data-v-70925687] {
  font-size: 0.3rem;
  color: #fff;
  width: 100%;
  height: 3.88rem;
  background-image: url('../../../assets/img_zhanghu_bg@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 0.8rem;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.account-page header[data-v-70925687] {
    background-image: url('../../../assets/img_zhanghu_bg@3x.png');
}
}
.account-page header .money[data-v-70925687] {
  font-size: 0.64rem;
  color: #fff;
  line-height: 0.9rem;
}
.account-page header .link-box[data-v-70925687] {
  width: 100%;
  height: 1rem;
  font-size: 0.32rem;
  color: #fff;
  padding: 0 1.54rem;
}
.account-page header .link-box .line[data-v-70925687] {
  width: 0.02rem;
  height: 0.6rem;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.7)), color-stop(50%, #ffffff), to(rgba(255, 255, 255, 0.7)));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #ffffff 50%, rgba(255, 255, 255, 0.7) 100%);
  opacity: 0.5;
}
.account-page .tab-box[data-v-70925687] {
  font-size: 0.32rem;
  color: #999;
  width: 100%;
  height: 1.04rem;
  background: #fff;
  padding: 0 0.62rem;
}
.account-page .tab-box .tab-item[data-v-70925687] {
  width: 1.26rem;
  height: 100%;
  border-bottom: 0.04rem solid transparent;
}
.account-page .tab-box .select-sitem[data-v-70925687] {
  font-size: 0.32rem;
  color: var(--main-color);
  border-bottom: 0.04rem solid var(--main-color);
}
.account-page .list[data-v-70925687] {
  padding: 0 0.24rem;
  background: #fff;
}
.account-page .list .list-item[data-v-70925687] {
  width: 100%;
  height: 1.4rem;
  padding: 0.24rem 0;
  border-bottom: 1px solid #F0F0F0;
  font-size: 0.32rem;
  color: #333;
  /*增加*/
  /* 减少*/
}
.account-page .list .list-item .left-info[data-v-70925687] {
  height: 100%;
}
.account-page .list .list-item .left-info div[data-v-70925687] {
  line-height: 0.44rem;
}
.account-page .list .list-item .time[data-v-70925687] {
  font-size: 0.24rem;
  color: #999;
}
.account-page .list .list-item .increase[data-v-70925687] {
  font-size: 0.36rem;
  color: #FF5252;
}
.account-page .list .list-item .decrease[data-v-70925687] {
  font-size: 0.36rem;
  color: var(--main-color);
}
.account-page .empty-box[data-v-70925687] {
  height: calc(100vh - 4.92rem);
}
.account-page .empty-box .empty[data-v-70925687] {
  margin-top: 2rem;
}
