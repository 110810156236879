.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.account-page {
  /*tab*/
  /*列表*/
  /* 列表为空*/
}
.account-page header {
  font-size: 0.3rem;
  color: #fff;
  width: 100%;
  height: 3.88rem;
  background-image: url('../../../assets/img_zhanghu_bg@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 0.8rem;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .account-page header {
    background-image: url('../../../assets/img_zhanghu_bg@3x.png');
  }
}
.account-page header .money {
  font-size: 0.64rem;
  color: #fff;
  line-height: 0.9rem;
}
.account-page header .link-box {
  width: 100%;
  height: 1rem;
  font-size: 0.32rem;
  color: #fff;
  padding: 0 1.54rem;
}
.account-page header .link-box .line {
  width: 0.02rem;
  height: 0.6rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #ffffff 50%, rgba(255, 255, 255, 0.7) 100%);
  opacity: 0.5;
}
.account-page .tab-box {
  font-size: 0.32rem;
  color: #999;
  width: 100%;
  height: 1.04rem;
  background: #fff;
  padding: 0 0.62rem;
}
.account-page .tab-box .tab-item {
  width: 1.26rem;
  height: 100%;
  border-bottom: 0.04rem solid transparent;
}
.account-page .tab-box .select-sitem {
  font-size: 0.32rem;
  color: var(--main-color);
  border-bottom: 0.04rem solid var(--main-color);
}
.account-page .list {
  padding: 0 0.24rem;
  background: #fff;
}
.account-page .list .list-item {
  width: 100%;
  height: 1.4rem;
  padding: 0.24rem 0;
  border-bottom: 1px solid #F0F0F0;
  font-size: 0.32rem;
  color: #333;
  /*增加*/
  /* 减少*/
}
.account-page .list .list-item .left-info {
  height: 100%;
}
.account-page .list .list-item .left-info div {
  line-height: 0.44rem;
}
.account-page .list .list-item .time {
  font-size: 0.24rem;
  color: #999;
}
.account-page .list .list-item .increase {
  font-size: 0.36rem;
  color: #FF5252;
}
.account-page .list .list-item .decrease {
  font-size: 0.36rem;
  color: var(--main-color);
}
.account-page .empty-box {
  height: calc(100vh - 4.92rem);
}
.account-page .empty-box .empty {
  margin-top: 2rem;
}
